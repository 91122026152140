<template>
  <travio-center-container grid-width="1/2" pageTitle="Edit Application">
    <vx-card>
      <vs-tabs class="tabs-shadow-none">
        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-settings" label="General Info">
          <div class="flex flex-wrap items-center mb-base mt-6">
            <vs-avatar :src="form.logoUrl" icon="cloud_upload" @click="$refs.companyLogoInput.click()" size="70px" class="mr-4 mb-4 company-logo-avatar" />
            <div>
              <input type="file" hidden id="companyLogoInput" ref="companyLogoInput" v-on:change="handleFileUpload"/>
              <vs-button @click="$refs.companyLogoInput.click()" class="mr-4 sm:mb-0 mb-2">Upload App Logo</vs-button>
              <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
              <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
            </div>
          </div>

          <vs-input class="w-full mt-4" label="Application Name*" v-model="form.applicationName" v-validate="'max:50'" name="applicationName" />
          <span class="text-danger text-sm">{{ errors.first('applicationName') }}</span>

          <div class="mt-4">
            <label class="text-sm">Web Platform</label>
            <v-select :value="selectedWebPlatform" append-to-body @input="setSelectedWebPlatform" :options="webPlatformOptions" />
          </div>
          <div class="mt-4">
            <label class="text-sm">Subscription*</label>
            <v-select :value="selectedSubscription" append-to-body @input="setSelectedSubscription" :options="subscriptionOptions" />
          </div>

          <div class="flex items-center mt-4">
            <vs-switch v-model="form.isDemo" />
            <span class="ml-4">Is Demo</span>
          </div>
          <div class="flex items-center mt-4">
            <vs-switch v-model="form.isActive" />
            <span class="ml-4">Is Active</span>
          </div>      
         
        </vs-tab>
        
        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-map-pin" label="Location">
          <div class="mt-4">
            <label class="text-sm">Region*</label>
            <v-select :value="selectedRegion" @input="setSelectedRegion" append-to-body :options="regionOptions" />
          </div>
          <vs-input class="w-full mt-4" label="Address 1" v-model="form.address1" v-validate="'max:250'" name="address1" />
          <span class="text-danger text-sm">{{ errors.first('address1') }}</span>
          <vs-input class="w-full mt-4" label="Address 2" v-model="form.address2" v-validate="'max:250'" name="address2" />
          <span class="text-danger text-sm">{{ errors.first('address2') }}</span>

          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2">
              <vs-input class="w-full mt-4" label="City" v-model="form.city" v-validate="'max:50'" name="city" />
              <span class="text-danger text-sm">{{ errors.first('city') }}</span>
            </div>
            <div class="vx-col w-full md:w-1/2">
              <vs-input class="w-full mt-4" label="State" v-model="form.state" v-validate="'max:50'" name="state" />
              <span class="text-danger text-sm">{{ errors.first('state') }}</span>
            </div>
          </div>

          <!-- Country -->
          <div class="mt-4">
            <label class="text-sm">Country</label>
            <v-select :value="selectedCountry" @input="setSelectedCountry" append-to-body :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          </div>
        </vs-tab>

        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-calendar" label="Booking">
          <!-- Booking Col -->
          <div>
            <vs-input class="w-full mt-4" label="Booking Email Name" v-model="form.bookingEmailName" v-validate="'max:50'" name="bookingEmailName" />
            <span class="text-danger text-sm">{{ errors.first('bookingEmailName') }}</span>

            <vs-input class="w-full mt-4" label="Booking Email" v-model="form.bookingEmail" v-validate="'max:100|email'" name="bookingEmail" />
            <span class="text-danger text-sm">{{ errors.first('bookingEmail') }}</span>

            <vs-input class="w-full mt-4" label="Booking Telephone Name" v-model="form.bookingTelephoneName" v-validate="'max:150'" name="bookingTelephoneName" />
            <span class="text-danger text-sm">{{ errors.first('bookingTelephoneName') }}</span>

            <vs-input class="w-full mt-4 mb-8" label="Booking Telephone" v-model="form.bookingTelephone" v-validate="'max:20'" name="bookingTelephone" />
            <span class="text-danger text-sm">{{ errors.first('bookingTelephone') }}</span>

          </div>
        </vs-tab>

        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-info" label="Support">
          <vs-input class="w-full mt-4" label="Support Email Name" v-model="form.supportEmailName" v-validate="'max:50'" name="supportEmailName" />
          <span class="text-danger text-sm">{{ errors.first('supportEmailName') }}</span>

          <vs-input class="w-full mt-4" label="Support Email" v-model="form.supportEmail" v-validate="'max:100|email'" name="supportEmail" />
          <span class="text-danger text-sm">{{ errors.first('supportEmail') }}</span>

          
          <vs-input class="w-full mt-4" label="Support Telephone Name" v-model="form.supportTelephoneName" v-validate="'max:150'" name="supportTelephoneName" />
          <span class="text-danger text-sm">{{ errors.first('supportTelephoneName') }}</span>

          <vs-input class="w-full mt-4" label="Support Telephone" v-model="form.supportTelephone" v-validate="'max:20'" name="supportTelephone" />
          <span class="text-danger text-sm">{{ errors.first('supportTelephone') }}</span>
        </vs-tab>

        <vs-tab class="fixed-height-app-edit" icon-pack="feather" icon="icon-code" label="API">
          <div class="vx-row mt-4">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Allow Public API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-switch v-model="form.allowPublicApiKey" />
            </div>
          </div>

          <div class="vx-row mt-4" v-if="form.allowPublicApiKey">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Public API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-input class="w-full" v-model="form.publicApiKey" v-validate="'required'" name="Public API Key" :disabled="true"/>
              <span class="text-danger text-sm">{{ errors.first('Public API Key') }}</span>
            </div>
          </div>
          <div class="flex flex-wrap justify-end" v-if="form.allowPublicApiKey">
            <vs-button 
              @click="regeneratePublicApi" 
              type="border" color="danger" 
              icon-pack="feather"
              icon="icon-refresh-cw" 
              title="Regenerate public api key"
              class="mt-4 mr-4">{{isSmallerScreen ? '': 'Regenerate'}}
            </vs-button>
            <vs-button 
              v-clipboard:copy="form.publicApiKey"
              v-clipboard:success="onCopy" 
              icon-pack="feather" icon="icon-clipboard" class="mt-4"
              title="Copy To Clipboard"
            >{{isSmallerScreen ? '': 'Copy To Clipboard'}}
            </vs-button>
          </div>

          <div class="vx-row mt-12">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Allow Private API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-switch v-model="form.allowPrivateApiKey" />
            </div>
          </div>
          <div class="vx-row mt-12" v-if="form.allowPrivateApiKey">
            <div class="vx-col sm:w-1/4 w-full">
              <span>Private API Key</span>
            </div>
            <div class="vx-col sm:w-3/4 w-full">
              <vs-input class="w-full" v-model="form.privateApiKey" v-validate="'required'" name="Private API Key" :disabled="true" />
              <span class="text-danger text-sm">{{ errors.first('Private API Key') }}</span>
            </div>
          </div>
          <div class="flex flex-wrap justify-end" v-if="form.allowPrivateApiKey">
            <vs-button 
              @click="regeneratePrivateApi" 
              type="border" color="danger" 
              icon-pack="feather"
              icon="icon-refresh-cw" 
              title="Regenerate private api key"
              class="mt-4 mr-4">{{isSmallerScreen ? '': 'Regenerate'}}
            </vs-button>
            <vs-button 
              v-clipboard:copy="form.privateApiKey" 
              v-clipboard:success="onCopy" 
              title="Copy To Clipboard"
              icon-pack="feather" icon="icon-clipboard" class="mt-4">{{isSmallerScreen ? '': 'Copy To Clipboard'}}
            </vs-button>
          </div>
        </vs-tab>
        
      </vs-tabs>
      
      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'
import { v4 as uuidv4 } from 'uuid';

export default {
  props: { 
    companyId: { required: true },
    applicationId: { required: true }
  },
  data () {
    return {
      form: new Form({
        applicationName: '',
        regionId: 0,
        subscriptionId: 0,
        webPlatformId: null,
        logoUrl: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        countryCode: '',

        bookingEmailName: '',
        bookingEmail: '',
        bookingTelephoneName: '',
        bookingTelephone: '',

        supportEmailName: '',
        supportEmail: '',
        supportTelephoneName: '',
        supportTelephone: '',

        publicApiKey: '',
        allowPublicApiKey: false,
        privateApiKey: '',
        allowPrivateApiKey: false,

        logoFile: null
      }),
      selectedCountry: null,
      selectedRegion: null,
      selectedSubscription: null,
      selectedWebPlatform: null,
      subscriptionOptions: [],
      regionOptions: [],
      webPlatformOptions: [],
      hasDataLoaded: false
    }
  },
  computed: {
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  mounted () {
    this.hasDataLoaded = false
    this.$http.get(`api/companies/${this.companyId}/applications/lookups`)
    .then(response => {
      this.regionOptions = response.data.regionOptions
      this.subscriptionOptions = response.data.subscriptionOptions
      this.webPlatformOptions = response.data.webPlatformOptions

      this.$http.get(`api/admin/companies/${this.companyId}/apps/${this.applicationId}`)
      .then(response => {
        this.form = new Form(Object.assign(this.form.data(), response.data))
        this.selectedCountry = this.countryOptions.find(x => x.code == response.data.countryCode) || null
        this.selectedSubscription = this.subscriptionOptions.find(x => x.code == response.data.subscriptionId) || {};
        this.selectedWebPlatform = this.webPlatformOptions.find(x => x.code == response.data.webPlatformId) || {};
        this.selectedRegion = this.regionOptions.find(x => x.code == response.data.regionId) || {};
        this.hasDataLoaded = true
      })
      .catch(err => console.error(err) )

    })
    .catch(err => console.error(err) )

    
  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    setSelectedRegion (value) {
      this.form.regionId = value ? value.code : null
      this.selectedRegion = value;
    },
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    setSelectedSubscription (value) {
      this.form.subscriptionId = value ? value.code : null
      this.selectedSubscription = value;
    },
    setSelectedWebPlatform (value) {
      this.form.webPlatformId = value ? value.code : null
      this.selectedWebPlatform = value;
    },
    handleFileUpload () {
      this.form.logoFile = this.$refs.companyLogoInput.files[0];
      // Temporary render file, can't upload yet since Application ID is not yet available
      if (this.form.logoFile) {
        this.form.logoUrl = URL.createObjectURL(this.form.logoFile);
      }
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData();
          const postData = this.form.data()
          let props = Object.keys(postData)
          props.forEach(key => {
            //skip nulls since it will be parsed as "null"
            postData[key] && formData.append(key, postData[key]);  
          })
          
          this.$vs.loading()
          this.$http.put(`api/admin/companies/${this.companyId}/apps/${this.applicationId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
          })
          .then(response => {
            this.$_notifySuccess('Company successfully updated.')
            this.$router.push({ name: 'admin-crm-company-edit', params: { companyId: this.companyId, presetTabIndex: 2 } })
          })
          .catch(error => this.$_notifyFailureByResponseData(error.response.data))
          .finally(() => this.$vs.loading.close())
        }
      })
    },
    regeneratePublicApi () {
      this.form.publicApiKey = uuidv4().toUpperCase()
    },
    regeneratePrivateApi () {
      this.form.privateApiKey = uuidv4().toUpperCase()
    },
    onCopy () {
      this.$_notifySuccess('API Key copied to clipboard')
    },
    handleReset () {
      this.selectedCountry = null
      this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push({ name: 'admin-crm-company-edit', params: { companyId: this.companyId, presetTabIndex: 2 } })
    },
    removeLogo () {
      this.$refs.companyLogoInput.value = '';
      this.form.logoUrl = '';
      this.form.logoFile = null;
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }

  .fixed-height-app-edit {
    height: calc(var(--vh, 1vh) * 100 - 38rem);
    /* overflow-y: hidden; */
    overflow-x: hidden;
    position: relative;
  }
</style>